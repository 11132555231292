/* eslint-env window */
import {
  AppBar,
  Box,
  CircularProgress,
  CssBaseline,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  Tab,
  Toolbar,
} from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Menu as MenuIcon, Room as RoomIcon } from '@material-ui/icons';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import MapGL, { Layer, Marker, Source } from '@urbica/react-map-gl';
import axios from 'axios';
import csvtojson from 'csvtojson';
import 'mapbox-gl/dist/mapbox-gl.css';
import * as React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import FilterDrawer from './components/FilterDrawer';
import ListDrawer from './components/ListDrawer';
import LocationInfoDrawer from './components/LocationInfoDrawer';
import icon from './icon.png';
import provincies from './provincies.json';
import querystring from 'query-string';

function hashCode(str) {
  // java String#hashCode
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
}

function intToRGB(i) {
  var c = (i & 0x00ffffff).toString(16).toUpperCase();

  return '#' + '00000'.substring(0, 6 - c.length) + c;
}

const drawerWidth = 540;

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },

  tabPanel: {
    padding: 0,
  },

  toolbar: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    ...theme.mixins.toolbar,
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginRight: drawerWidth,
      display: 'none',
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    height: '100%',
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginRight: drawerWidth,
    },
  },
}));

const initialState = { locationDrawer: false };

function removeKeyIfExist(key, array) {
  const index = array.indexOf(key);
  if (index > -1) {
    array.splice(index, 1);
  }
}

function reducer(state, action) {
  switch (action.type) {
    case 'TOGGLE_LOCATION_DRAWER':
      return { ...state, locationDrawer: !state.locationDrawer };

    default:
      throw new Error();
  }
}

function App() {
  const classes = useStyles();

  let { sheetId } = useParams();
  const theme = useTheme();

  const [csvUrl, setCsvUrl] = React.useState(null);

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [viewport, setViewport] = React.useState({
    longitude: 5.600489,
    latitude: 52.21158,
    zoom: 7,
    bearing: 0,
    pitch: 0,
    width: 470,
    height: 900,
  });

  const [locations, setLocations] = React.useState([]);

  const [locationList, setLocationList] = React.useState([]);

  const [value, setValue] = React.useState('filters');
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [selectedLocation, setSelectedLocation] = React.useState(-1);
  const [selectedLocationProfile, setSelectedLocationProfile] =
    React.useState(undefined);

  const [filters, setFilters] = React.useState({});
  const [settings, setSettings] = React.useState({});
  const [markerColors, setMarkerColors] = React.useState({});

  let filtersKeys = Object.keys(filters);

  let location = useLocation();
  let search = querystring.parse(location.search);
  let filterValues = search.filter.split(',');

  React.useEffect(() => {
    async function getLocations() {
      const csvDataResponse = await axios.get(csvUrl);
      const csvData = csvDataResponse.data;
      const locationsItems = await csvtojson().fromString(csvData);
      setLocations(locationsItems);
    }
    if (csvUrl) {
      getLocations();
    }
  }, [csvUrl]);

  React.useEffect(() => {
    async function testSheetId() {
      const csvDocsUrl = `https://docs.google.com/spreadsheets/d/${sheetId}/gviz/tq?tqx=out:csv&sheet=name`;

      try {
        const csvDataResponse = await axios.get(csvDocsUrl);

        setCsvUrl(csvDocsUrl);
      } catch (e) {
        console.log(e);
      }
    }

    if (sheetId !== undefined) {
      testSheetId();
    }
  }, [sheetId]);

  React.useEffect(() => {
    if (locations.length > 0) {
      console.log(locations[0]);
      let groups = [];
      let filtersObj = {};
      let allUniqueValues = {};
      let allValues = {};
      let firstLocation = locations[0];
      let keys = Object.keys(firstLocation);

      removeKeyIfExist('name', keys);
      removeKeyIfExist('location', keys);

      // Initialize allValues object
      keys.forEach((key) => {
        allValues[key] = [];
      });

      // Get all possible values
      locations.forEach((location) => {
        if (location.group) {
          groups.push(location.group);
        }
        keys.forEach((key) => {
          allValues[key].push(location[key]);
        });
      });

      // Get all unique possible values
      keys.forEach((key) => {
        allUniqueValues[key] = [...new Set(allValues[key])];
      });

      // If all unique possible values is less or equal than 30
      // And there enough are repeating values
      // Create filters
      keys.forEach((key) => {
        if (
          (allUniqueValues[key].length < 40 &&
            allValues[key].length - allUniqueValues[key].length > 10) ||
          key === 'group' ||
          filterValues.includes(key)
        ) {
          filtersObj[key] = {
            options: allUniqueValues[key],
          };
        }
      });

      setFilters(filtersObj);

      let uniqueGroups = [...new Set(groups)];

      let markerCol = {};

      let mColors = ['#e0257b', '#f7e400'];

      uniqueGroups.forEach((groupName, index) => {
        markerCol[groupName] = mColors[index]
          ? mColors[index]
          : intToRGB(hashCode(groupName));
      });

      setMarkerColors(markerCol);
    }
  }, [locations, filterValues]);

  React.useEffect(() => {
    let filteredLocations = [];

    let settingKeys = Object.keys(settings);

    locations.forEach((location) => {
      let isVisible = true;

      settingKeys.forEach((sKey) => {
        if (settings[sKey].length > 0) {
          // if ((settings[sKey].indexOf(location[sKey]) === -1) === false) {
          //   console.log("----------start-------------------");
          //   console.log(settings[sKey]);
          //   console.log(location[sKey]);
          //   console.log(settings[sKey].indexOf(location[sKey]) === -1);
          //   console.log("----------end-------------------");
          // }

          if (settings[sKey].indexOf(location[sKey]) === -1) {
            isVisible = false;
          }
        }
      });

      if (isVisible === true) {
        // console.log({ group: location.group });
        filteredLocations.push(location);
      }
    });

    setLocationList([]);
    setLocationList(filteredLocations);
  }, [locations, settings]);

  React.useEffect(() => {
    if (Object.keys(filters).length === 0) {
      setValue('locations');
    } else {
      setValue('filters');
    }
  }, [filters]);

  if (locations.length === 0) {
    return (
      <Box className="App">
        <Box position="fixed" top="50%" left="50%">
          <CircularProgress />
        </Box>
      </Box>
    );
  }

  return (
    <div className={classes.root}>
      <CssBaseline />

      <Drawer
        anchor={'left'}
        BackdropProps={{ invisible: true }}
        open={state.locationDrawer}
        onClose={() => {
          dispatch({
            type: 'TOGGLE_LOCATION_DRAWER',
          });
          setSelectedLocation(-1);
          setSelectedLocationProfile(undefined);
        }}
      >
        <LocationInfoDrawer
          selectedLocationProfile={selectedLocationProfile}
          markerColors={markerColors}
        />
      </Drawer>

      <AppBar position="fixed" color="default" className={classes.appBar}>
        <Toolbar>
          <Grid justify="space-between" container spacing={10}>
            <Grid item>
              <img height="40px" src={icon} alt="logo" />
            </Grid>
            <Grid item>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="end"
                onClick={() => {
                  setMobileOpen(!mobileOpen);
                }}
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      <nav className={classes.drawer}>
        <Hidden mdUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={'right'}
            open={mobileOpen}
            onClose={() => {
              setMobileOpen(!mobileOpen);
            }}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            <FilterDrawer
              filters={filters}
              settings={settings}
              setSettings={setSettings}
            />
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            anchor={'right'}
            variant="permanent"
            open
          >
            <TabContext value={value}>
              <AppBar position="sticky" color="primary">
                <TabList
                  onChange={(event, newValue) => {
                    setValue(newValue);
                  }}
                  variant="fullWidth"
                >
                  <Tab
                    label="Filters"
                    value="filters"
                    disabled={filtersKeys.length === 0}
                  />
                  <Tab label="Locaties" value="locations" />
                </TabList>
              </AppBar>

              <TabPanel
                className={classes.tabPanel}
                value={'filters'}
                index={0}
              >
                <FilterDrawer
                  filters={filters}
                  settings={settings}
                  setSettings={setSettings}
                />
              </TabPanel>

              <TabPanel
                className={classes.tabPanel}
                value={'locations'}
                index={1}
              >
                <ListDrawer
                  dispatch={dispatch}
                  state={state}
                  locationList={locationList}
                  setSelectedLocation={setSelectedLocation}
                  setSelectedLocationProfile={setSelectedLocationProfile}
                />
              </TabPanel>
            </TabContext>
          </Drawer>
        </Hidden>
      </nav>

      <main className={classes.content}>
        <div className={classes.toolbar} />
        <div style={{ width: '100%', height: '100%' }} id="map">
          <MapGL
            preserveDrawingBuffer={true}
            style={{ width: '100%', height: '100%' }}
            accessToken={
              'pk.eyJ1Ijoia29lbmZpIiwiYSI6ImNrMnZ5ajBjbjAwNm8zZHBxYTExbXZodTUifQ.Fs8w4wRz12_kW3TGwK-LRQ'
            }
            onViewportChange={setViewport}
            {...viewport}
          >
            <Source id="rayons" type="geojson" data={provincies} />
            <Layer
              id="rayons"
              type="fill"
              source="rayons"
              paint={{
                // "fill-color": ["get", "color"],
                'fill-color': theme.palette.primary.main,
                'fill-opacity': 0.2,
              }}
            />

            {locationList.map((location, index) => {
              let htmlColor =
                location.name === selectedLocation
                  ? blue[500]
                  : theme.palette.secondary.main;

              if (location.group && markerColors[location.group]) {
                htmlColor = markerColors[location.group];
              }

              if (
                selectedLocationProfile &&
                selectedLocationProfile.name === location.name
              ) {
                htmlColor = blue[500];
              }

              if (location.location.indexOf(',') === -1) {
                return null;
              }

              return (
                <Marker
                  key={location.name + index}
                  longitude={Number(location.location.split(',')[1].trim())}
                  latitude={Number(location.location.split(',')[0].trim())}
                  anchor="bottom"
                  onClick={() => {
                    setSelectedLocation(location.name);
                    setSelectedLocationProfile(location);
                    dispatch({
                      type: 'TOGGLE_LOCATION_DRAWER',
                    });
                  }}
                >
                  <RoomIcon
                    height="24" // NEEDED FOR EXPORT
                    width="24" // NEEDED FOR EXPORT
                    stroke={'black'}
                    htmlColor={htmlColor}
                    strokeWidth={0.7}
                    fontSize={
                      selectedLocationProfile &&
                      selectedLocationProfile.name === location.name
                        ? 'large'
                        : location.name === selectedLocation
                        ? 'large'
                        : 'small'
                    }
                  />
                </Marker>
              );
            })}
          </MapGL>
        </div>
      </main>
    </div>
  );
}

export default App;
