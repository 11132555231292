import { CssBaseline, Dialog } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MapGL from "@urbica/react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import * as React from "react";
import GoogleSheetsDialog from "../components/GoogleSheetsDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },

  toolbar: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    ...theme.mixins.toolbar,
  },
}));

function GoogleSheets() {
  const classes = useStyles();

  const [viewport] = React.useState({
    longitude: 5.600489,
    latitude: 52.21158,
    zoom: 7,
    bearing: 0,
    pitch: 0,
    width: 470,
    height: 900,
  });

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Dialog maxWidth="sm" fullWidth open={true}>
        <GoogleSheetsDialog />
      </Dialog>

      <div className={classes.toolbar} />
      <div style={{ width: "100%", height: "100%" }} id="map">
        <MapGL
          preserveDrawingBuffer={true}
          style={{ width: "100%", height: "100%" }}
          accessToken={
            "pk.eyJ1Ijoia29lbmZpIiwiYSI6ImNrMnZ5ajBjbjAwNm8zZHBxYTExbXZodTUifQ.Fs8w4wRz12_kW3TGwK-LRQ"
          }
          {...viewport}
        ></MapGL>
      </div>
    </div>
  );
}

export default GoogleSheets;
