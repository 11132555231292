import { Box, Button, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import * as React from "react";
import exportMap from "../utilities/exportMap";

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(2, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
}));

function FilterDrawer({ filters, settings, setSettings }) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.paper}>
        <Box width="100%">
          <div className={classes.form}>
            {Object.keys(filters).map((filterKey) => {
              return (
                <Box key={filterKey} width="100%" mt={2}>
                  <Autocomplete
                    multiple
                    options={filters[filterKey].options}
                    onChange={(event, selectedFilter) => {
                      setSettings({
                        ...settings,
                        [filterKey]: selectedFilter,
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label={filterKey === "group" ? "Groep" : filterKey}
                        placeholder={
                          filterKey === "group" ? "Groep" : filterKey
                        }
                        fullWidth
                      />
                    )}
                  />
                </Box>
              );
            })}

            <Box marginTop="40px">
              <Button
                variant="contained"
                onClick={() => {
                  exportMap();
                }}
                fullWidth
              >
                Exporteer als Afbeelding
              </Button>
            </Box>
          </div>
        </Box>
      </div>
    </>
  );
}

export default FilterDrawer;
