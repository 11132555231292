import {
  AppBar,
  Box,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Toolbar,
} from "@material-ui/core";
import { fade, makeStyles } from "@material-ui/core/styles";
import { Clear, Search } from "@material-ui/icons";
import Fuse from "fuse.js";
import _groupBy from "lodash/groupBy";
import * as React from "react";
import useDebounce from "../hooks/useDebounce";

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },

    marginLeft: 0,
    width: "100%",
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
}));

function ListDrawer({
  locationList,
  dispatch,
  setSelectedLocation,
  setSelectedLocationProfile,
  state,
}) {
  const classes = useStyles();

  const [filteredLocationList, setFilteredLocationList] = React.useState([]);

  const [searchTerm, setSearchTerm] = React.useState("");

  const debouncedSelectedQuery = useDebounce(searchTerm, 200);

  React.useEffect(() => {
    const fuse = new Fuse(locationList, {
      keys: ["name"],
    });

    let fuseSearch = fuse.search(debouncedSelectedQuery);

    fuseSearch = fuseSearch.map((fuzA) => {
      return fuzA.item;
    });

    if (!debouncedSelectedQuery) {
      fuseSearch = locationList;
    }

    let grouped = _groupBy(fuseSearch, function (b) {
      return b.group;
    });

    setFilteredLocationList(grouped);
  }, [locationList, debouncedSelectedQuery]);

  return (
    <>
      <AppBar color="default" position="sticky">
        <Toolbar>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <Search />
            </div>
            <InputBase
              placeholder="Filter..."
              value={searchTerm}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ "aria-label": "filter" }}
              endAdornment={
                <IconButton onClick={() => setSearchTerm("")}>
                  <Clear />
                </IconButton>
              }
              onChange={(event) => {
                setSearchTerm(event.target.value);
              }}
            />
          </div>
        </Toolbar>
      </AppBar>

      <div className={classes.paper}>
        <Box width="100%" overflow="auto">
          <List>
            {Object.keys(filteredLocationList).map(function (key, index) {
              return (
                <React.Fragment key={key}>
                  <ListSubheader color="primary">
                    {key !== "undefined" ? key : "Standaardgroep"}
                  </ListSubheader>

                  {filteredLocationList[key].map((location, index) => {
                    return (
                      <ListItem
                        key={"list" + location.name + index}
                        onMouseEnter={() => {
                          setSelectedLocation(location.name);
                        }}
                        onMouseLeave={() => {
                          setSelectedLocation(-1);
                        }}
                        onClick={() => {
                          setSelectedLocation(location.name);
                          setSelectedLocationProfile(location);
                          dispatch({
                            type: "TOGGLE_LOCATION_DRAWER",
                          });
                        }}
                        button
                      >
                        <ListItemText primary={location.name} />
                      </ListItem>
                    );
                  })}
                </React.Fragment>
              );
            })}
          </List>
        </Box>
      </div>
    </>
  );
}

export default ListDrawer;
