import html2canvas from "html2canvas";

function exportMap() {
  html2canvas(document.getElementById("map"), {
    useCORS: true,
    imageTimeout: 0,
    allowTaint: true,
  }).then(function (canvas) {
    let img = canvas.toDataURL("image/png");
    let link = document.createElement("a");
    link.href = img;
    link.download = "map.png";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });
}

export default exportMap;
