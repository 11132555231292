/* eslint-env window */
import {
  Avatar,
  Box,
  Button,
  Grid,
  Link,
  MobileStepper,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import axios from "axios";
import csvtojson from "csvtojson";
import "mapbox-gl/dist/mapbox-gl.css";
import { useSnackbar } from "notistack";
import * as React from "react";
import { useHistory } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import copy from "../copy.png";
import dataStudio from "../data-studio.png";
import icon from "../icon.png";
import share from "../share.png";
import template from "../template.png";
import DividerWithText from "./DividerWithText";

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

async function checkSheetData(csvData) {
  const locationsItems = await csvtojson().fromString(csvData);

  if (locationsItems.length === 0) {
    return false;
  }

  let firstLocation = locationsItems[0];

  if (
    firstLocation.name !== undefined &&
    firstLocation.location !== undefined
  ) {
    return true;
  } else {
    return false;
  }
}

function GoogleSheetsDialog() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const classes = useStyles();

  const theme = useTheme();

  const history = useHistory();

  const [activeStep, setActiveStep] = React.useState(0);
  const [googleSheetlist, setGoogleSheetlist] = React.useState("");

  async function checkId() {
    const googleSheetsPatt = new RegExp("/spreadsheets/d/([a-zA-Z0-9-_]+)");
    const googleSheetsUrlIsValid = googleSheetsPatt.test(googleSheetlist);

    if (googleSheetsUrlIsValid === true) {
      const matches = /\/([\w-_]{15,})\/(.*?gid=(\d+))?/.exec(googleSheetlist);
      const csvDocsUrl = `https://docs.google.com/spreadsheets/d/${matches[1]}/gviz/tq?tqx=out:csv&sheet=name`;
      try {
        let csvResponse = await axios.get(csvDocsUrl);
        let csvData = csvResponse.data;
        let dataIsValid = await checkSheetData(csvData);

        if (dataIsValid === true) {
          history.push(`/s/${matches[1]}`);
        } else {
          enqueueSnackbar(
            "Google Sheets Url is geldig, alleen niet data voldoet niet aan formaat",
            {
              variant: "error",
            }
          );
        }
      } catch (e) {
        enqueueSnackbar("Link ophalen/Iedereen met de link staat niet aan", {
          variant: "error",
        });
        console.log(e);
      }
    } else {
      enqueueSnackbar("Google Sheets Url is niet geldig", {
        variant: "error",
      });
    }
  }

  return (
    <>
      <Box m={2} display="flex" flexDirection="column" alignItems="center">
        <Box m={2}>
          <Avatar className={classes.avatar} src={icon}></Avatar>
        </Box>
        <Typography component="h1" variant="h5">
          Gladior Data Kaart
        </Typography>

        <Box m={2} display="flex" flexDirection="row" width="100%">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                label="Google Sheets Url"
                variant="filled"
                onChange={(event) => {
                  setGoogleSheetlist(event.target.value);
                }}
                fullWidth
                placeholder="https://docs.google.com/spreadsheets/d/1NbGPJK5LOGcjZ9nlysADDcpzJA8PYIWzYzckglSWWgM/"
              />
            </Grid>

            <Grid item xs={12}>
              <Box
                display="flex"
                flexDirection="row"
                width="100%"
                justifyContent="center"
              >
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => {
                    checkId();
                  }}
                >
                  Genereer kaartje
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box m={2} width="100%">
          <DividerWithText>Instructies</DividerWithText>
        </Box>

        <Box m={2} width="100%">
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={activeStep}
            onChangeIndex={(step) => {
              setActiveStep(step);
            }}
            enableMouseEvents
          >
            <div>
              <Typography variant="h5" gutterBottom>
                Kopieer de template sheet
              </Typography>
              <Typography variant="body">
                Ga naar{" "}
                <Link
                  color="secondary"
                  href="https://docs.google.com/spreadsheets/d/1NbGPJK5LOGcjZ9nlysADDcpzJA8PYIWzYzckglSWWgM/edit#gid=0"
                >
                  Gladior Data Kaart - Base Sheet
                </Link>
              </Typography>
              <Box
                m={2}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <img style={{ width: "400px" }} src={template} alt="" />
              </Box>
            </div>

            <div>
              <Typography variant="h5" gutterBottom>
                Kopieer de template sheet
              </Typography>
              <Typography variant="body">
                Maak een kopie van{" "}
                <Link
                  color="secondary"
                  href="https://docs.google.com/spreadsheets/d/1NbGPJK5LOGcjZ9nlysADDcpzJA8PYIWzYzckglSWWgM/edit#gid=0"
                >
                  Gladior Data Kaart - Base Sheet
                </Link>
              </Typography>
              <Box
                m={2}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <img style={{ width: "400px" }} src={copy} alt="" />
              </Box>
            </div>

            <div>
              <Typography variant="h5" gutterBottom>
                Ze link deling aan
              </Typography>
              <Typography variant="body">
                {`Klik op "Delen" knop -> "Link ophalen" -> "Iedereen met de link"
                  -> "Lezer"`}
              </Typography>
              <Box
                m={2}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <img style={{ width: "400px" }} src={share} alt="" />
              </Box>
            </div>

            <div>
              <Typography variant="h5" gutterBottom>
                Google Data Studio
              </Typography>

              <Typography variant="body">
                Wil je het kaartje gebruiken in Google Data Studio ?{" "}
                <Link
                  color="secondary"
                  href="https://docs.google.com/document/d/1EaHQd6rS8RwhTZeWmy0mdRKJ0_cPWBnoIX78RQz3JSo/"
                >
                  Bekijk de instructies
                </Link>
              </Typography>
              <Box
                m={2}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <img style={{ width: "400px" }} src={dataStudio} alt="" />
              </Box>
            </div>
          </SwipeableViews>
          <MobileStepper
            steps={4}
            position="static"
            variant="text"
            activeStep={activeStep}
            nextButton={
              <Button
                size="small"
                onClick={() => {
                  setActiveStep((prevActiveStep) => prevActiveStep + 1);
                }}
                disabled={activeStep === 4 - 1}
              >
                Volgende
                {theme.direction === "rtl" ? (
                  <KeyboardArrowLeft />
                ) : (
                  <KeyboardArrowRight />
                )}
              </Button>
            }
            backButton={
              <Button
                size="small"
                onClick={() => {
                  setActiveStep((prevActiveStep) => prevActiveStep - 1);
                }}
                disabled={activeStep === 0}
              >
                {theme.direction === "rtl" ? (
                  <KeyboardArrowRight />
                ) : (
                  <KeyboardArrowLeft />
                )}
                Vorige
              </Button>
            }
          />
        </Box>
      </Box>
    </>
  );
}

export default GoogleSheetsDialog;
