import {
  AppBar,
  Box,
  CardContent,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Toolbar,
  Typography,
  useTheme,
} from "@material-ui/core";
import { Email, Info, Language, Phone, Room } from "@material-ui/icons";
import PhoneNumber from "awesome-phonenumber";
import isEmail from "is-email";
import isUrl from "is-url";
import * as React from "react";
import logo from "../logo.png";

function addhttp(url) {
  if (!/^(?:f|ht)tps?\:\/\//.test(url)) {
    url = "http://" + url;
  }
  return url;
}

function LocationInfoDrawer({ selectedLocationProfile, markerColors }) {
  let [contactItems, setContactItems] = React.useState([]);
  let [infoItems, setInfoItems] = React.useState([]);

  const theme = useTheme();

  React.useEffect(() => {
    if (selectedLocationProfile) {
      let contactArray = [];
      let infoArray = [];
      for (const [key, value] of Object.entries(selectedLocationProfile)) {
        if (isEmail(value.trim()) === true) {
          contactArray.push({
            title: key.trim(),
            value: value,
            type: "EMAIL",
          });
        } else if (
          (isUrl(value.trim()) === true ||
            value.startsWith("www.") ||
            isUrl(`http://${value.trim()}`) === true) &&
          value.indexOf(",") === -1
        ) {
          contactArray.push({
            title: key.trim(),
            value: addhttp(value),
            type: "WEBSITE",
          });
        } else if (new PhoneNumber(value, "NL").isValid()) {
          contactArray.push({
            title: key.trim(),
            value: value,
            type: "PHONE",
          });
        } else {
          if (
            value &&
            key !== "location" &&
            key !== "name" &&
            key !== "group"
          ) {
            infoArray.push({
              title: key.trim(),
              value: value,
            });
          }
        }
      }

      setContactItems(contactArray);
      setInfoItems(infoArray);
    }
  }, [selectedLocationProfile]);

  if (!selectedLocationProfile) {
    return null;
  }

  let htmlColor = theme.palette.secondary.main;

  if (
    selectedLocationProfile.group &&
    markerColors[selectedLocationProfile.group]
  ) {
    htmlColor = markerColors[selectedLocationProfile.group];
  }

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Box m={2}>
            <img height="30px" src={logo} alt="logo" />
          </Box>
        </Toolbar>
      </AppBar>
      <Box width="400px" m={2}>
        <CardContent>
          <Box display="flex" flexDirection={"row"}>
            <Box m={2}>
              {selectedLocationProfile.group && (
                <Box width="100%" display="flex" mb={2}>
                  <Chip
                    style={{
                      color: "rgba(0, 0, 0, 0.87)",
                      border: `1px solid ${htmlColor}`,
                    }}
                    icon={
                      <Box>
                        <Room
                          stroke={"black"}
                          htmlColor={htmlColor}
                          strokeWidth={0.7}
                        />
                      </Box>
                    }
                    label={selectedLocationProfile.group}
                    color="secondary"
                    variant="outlined"
                  />
                </Box>
              )}

              <Typography
                variant="h6"
                color="inherit"
                align={"left"}
                gutterBottom
              >
                {selectedLocationProfile.name}
              </Typography>
            </Box>
          </Box>
          <Divider />
          <List>
            {contactItems.length > 0 && (
              <>
                <ListSubheader>Contact</ListSubheader>
                {contactItems.map((item) => {
                  let icon;

                  switch (item.type) {
                    case "WEBSITE":
                      icon = <Language color="secondary" />;
                      break;
                    case "EMAIL":
                      icon = <Email color="secondary" />;
                      break;
                    case "PHONE":
                      icon = <Phone color="secondary" />;
                      break;
                    default:
                      icon = <Info color="secondary" />;
                  }

                  return (
                    <ListItem key={item.title}>
                      <ListItemIcon>{icon}</ListItemIcon>

                      <ListItemText
                        primary={item.value}
                        secondary={item.title}
                      />
                    </ListItem>
                  );
                })}
              </>
            )}

            {infoItems.length > 0 && (
              <>
                {contactItems.length > 0 && <Divider />}
                <ListSubheader>Overige informatie</ListSubheader>
                {infoItems.map((item) => {
                  return (
                    <ListItem key={item.title}>
                      <ListItemText
                        primary={item.value}
                        secondary={item.title}
                      />
                    </ListItem>
                  );
                })}
              </>
            )}
          </List>
        </CardContent>
      </Box>
    </>
  );
}

export default LocationInfoDrawer;
